import React from 'react';

import logoBlueBlanco from '../assets/img/logo-blue-blanco.svg';
import bell from '../assets/img/bell.svg';
import squares from '../assets/img/squares.svg';
import avatar from '../assets/img/avatars-naranjo-5.svg';
import menuMobile from '../assets/img/menu-mobile.svg';

function Header() {
  return (
    <nav id="header" className="navbar rounded-b-2xl shadow-md px-6 lg:px-8">
      <div className="relative flex items-center justify-between h-16 ">
        <div className="flex-1 flex items-stretch md:items-center justify-start">
          <div className="flex-shrink-0 flex items-center">
            <img
              className="mh-8 w-auto sm:mh-10"
              src={logoBlueBlanco}
              alt="Blue Express"
            />
          </div>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6">
          <div className="ml-3 relative">
            <button
              type="button"
              className="flex rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span className="sr-only">View notifications</span>
              <img className="rounded-full" src={bell} alt="" />
            </button>
          </div>
          <div className="ml-3 relative">
            <button
              type="button"
              className="flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span className="sr-only">View notifications</span>
              <img className="rounded-sm" src={squares} alt="" />
            </button>
          </div>
          <div className="ml-3 relative">
            <div>
              <button
                type="button"
                className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span className="sr-only">Open user menu</span>
                <img className="rounded-full" src={avatar} alt="" />
              </button>
            </div>
          </div>
          <div className="ml-3 relative">
            <div>
              {/* Mobile menu button */}
              <button
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>

                {/* Icon when menu is closed.

                  Heroicon name: outline/menu

                  Menu open: "hidden", Menu closed: "block" */}

                <img className="rounded-full" src={menuMobile} alt="" />

                {/* Icon when menu is open.

                  Heroicon name: outline/x

                  Menu open: "block", Menu closed: "hidden" */}

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
