import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import Container from '../components/Container';

import api from '../services/api';

import packageChecked from '../assets/img/package-checked.png';
import buildingPuntoPickup from '../assets/img/building-pickup.png';
import buildingHouse from '../assets/img/building-house.png';

function EleccionTipoLogisticaInversa() {
  const { hash } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    api
      .get(`/get-autorizacion-data/${hash}`)
      .then((result) => setOrder(result.data.data));
  }, []);

  return (
    <Container>
      <div className="max-w-2xl mx-auto p-5">
        <div className="flex flex-col justify-center content-center">
          <h1 className="text-center text-2xl font-extrabold mb-2">
            Autorización de devolución
          </h1>

          <div className="mx-auto">
            <img
              className="h-28 w-auto"
              src={packageChecked}
              alt="Devolución del paquete"
            />
          </div>

          <p className="text-center text-lg font-bold">
            OS <span className="text-orange">{order?.tracking}</span>
          </p>
        </div>
      </div>

      <div className="max-w-2xl mx-auto p-5 bg-white shadow-md rounded-2xl">
        <div className="flex flex-col justify-center content-center">
          <h2 className="text-xl text-left font-bold mb-5">
            ¿Cómo puedo realizar una devolución?
          </h2>

          <p className="text-left mb-5">
            Puedes programar el retiro en tu domicilio o llevar el producto a un
            punto BluePickup para acelerar la devolución.
          </p>

          <div className="flex flex-row flex-nowrap align-center justify-around md:justify-evenly">
            <div className="flex flex-col align-center justify-center">
              <p className="text-center mb-1">Punto Pickup</p>
              <Link to={`/punto-pickup/${hash}`}>
                <button
                  type="button"
                  className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <div className="rounded-full h-32 md:h-28 w-32 md:w-28 flex items-center justify-center bg-default-blue mx-auto">
                    <img src={buildingPuntoPickup} alt="Punto Pickup" />
                  </div>
                </button>
              </Link>
            </div>
            {order &&
              (order?.timesUpdated === undefined ||
                order?.timesUpdated < order?.config?.qtdCancel) &&
              order?.status !== 'canceled' && (
                <div className="flex flex-col align-center justify-center">
                  <p className="text-center mb-1">Retiro</p>
                  <Link to={`/programacion-del-retiro/${hash}`}>
                    <button
                      type="button"
                      className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <div className="rounded-full h-32 md:h-28 w-32 md:w-28 flex items-center justify-center bg-default-blue mx-auto">
                        <img src={buildingHouse} alt="Retiro" />
                      </div>
                    </button>
                  </Link>
                </div>
              )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default EleccionTipoLogisticaInversa;
