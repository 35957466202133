/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import ImageUploading from 'react-images-uploading';
import QrReader from 'react-qr-reader';
import { toast, ToastContainer } from 'react-toastify';
import Container from '../components/Container';
import qrcode from '../assets/img/qrcode.svg';

import 'react-toastify/dist/ReactToastify.min.css';

// import minus from '../assets/img/minus.svg';
// import checkedCircleGreen from '../assets/img/checked-circle-green.svg';
// import plusCircle from '../assets/img/plus-circle.svg';
// import remove from '../assets/img/remove.svg';
// import wow from '../assets/img/wow.svg';

// eslint-disable-next-line react/prop-types
function Hacer({ action }) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [showCamera, setShowCamera] = useState(false);
  // const action = 'retiro';

  const history = useHistory();
  // const { hash } = useParams();

  function handleSubmit(event) {
    event.preventDefault();
    toast.success('Registrado con éxito');
  }
  function handleClickReadQrCode(event) {
    setShowCamera(true);
    // event.preventDefault();
  }

  function handleScanQRCode(data) {
    if (data) {
      setShowCamera(false);
      setCodigo(data);
      toast.success(data.toString());
    }
  }
  function handleErrorQRCode(err) {
    console.error(err);
  }

  function handleClickVolver() {
    history.goBack();
  }

  useEffect(() => {
    setFormIsValid(codigo.length > 5);
  }, [codigo]);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div className="max-w-2xl mx-auto p-5">
          <div className="flex flex-col justify-center content-center">
            <h1 className="text-center text-3xl font-extrabold mb-2">
              Instrucciones para el {action}:
            </h1>
            {action === 'retiro' && (
              <>
                <div className="pt-5 grid grid-flow-col grid-cols-3 gap-4">
                  <div className="lista">1</div>
                  <div className="lista">2</div>
                  <div className="lista activeLista">3</div>
                </div>
                <div className="text-sm text-center pt-2 grid grid-flow-col grid-cols-3 gap-5">
                  <div>Documentacion</div>
                  <div>Empaque</div>
                  <div>Escanear</div>
                </div>
              </>
            )}

            {action === 'entrada' && (
              <div className="ml-16">
                <div className="pt-5 grid grid-flow-col grid-cols-3 gap-4">
                  <div className="lista">1</div>
                  <div className="lista activeLista">2</div>
                </div>
                <div className="text-sm text-center pt-2  grid grid-flow-col grid-cols-3 gap-5">
                  <div>Documentacion</div>
                  <div>Escanear</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="
                max-w-2xl
                mx-auto
                bg-white
                rounded-2xl
                overflow-hidden
                shadow-md"
        >
          <div className="flex flex-col divide-y text-lg">
            <div className="flex flex-row justify-between items-center px-5 py-4">
              <div className="flex-1 px-2 font-bold">
                <strong>Escanea el código de seguridad:</strong>
              </div>
            </div>
            <div className="mx-auto py-10">
              <button type="button" onClick={handleClickReadQrCode}>
                <img className="h-28 w-auto" src={qrcode} alt="QRCOD" />
              </button>
            </div>
            <div className="mx-auto text-center py-5">
              <p>Haz clic aquí y escanea el QRCOD.</p>
            </div>
            <div className="mx-auto text-center py-5">
              También puedes ingresar el código manualmente.
            </div>
            <div className="mx-auto text-center py-5">
              <div className="text-center py-5">
                <ToastContainer />
                <input
                  type="text"
                  name="codigo"
                  placeholder="Introduce el código"
                  className="input-borded rounded-xl bg-transparent w-full h-12 "
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                  value={codigo}
                />
              </div>{' '}
            </div>
          </div>
        </div>

        <div className="mt-5 text-center">
          <button
            type="submit"
            className="btn-primary w-4/5 "
            disabled={!formIsValid}
          >
            Enviar
          </button>
        </div>
      </form>
      <div className="mt-5 text-center">
        <button type="button" className="btn-link" onClick={handleClickVolver}>
          <FontAwesomeIcon icon={faArrowLeft} /> Volver atrás
        </button>
      </div>

      {showCamera && (
        <div
          aria-hidden
          className="justify-center bg-blue-700 max-h-screen bg-opacity-90 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-h-screen-75 mx-auto"
          onClick={() => setShowCamera(false)}
        >
          <div className="w-10/12 mx-auto">
            <QrReader
              delay={300}
              onError={handleErrorQRCode}
              onScan={handleScanQRCode}
              style={{ width: '100%' }}
            />
            <p className="text-center mt-5 text-white text-lg">
              Cuando se active la cámara, apunta tu teléfono sobre el código
              para escanearlo.
            </p>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Hacer;
