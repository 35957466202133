/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import useModal from 'react-hooks-use-modal';
import Autocomplete from 'react-autocomplete';

import api from '../services/api';

import Container from '../components/Container';

import mapStyles from '../utils/mapStyles';

import closeIcon from '../assets/img/close.png';
import puntosIcon from '../assets/img/sucursales-puntos.png';
import clock from '../assets/img/clock.svg';
import phone from '../assets/img/phone.svg';
import markerIcon from '../assets/img/marker.svg';
import dotBlue from '../assets/img/pin-04.svg';
import locate from '../assets/img/locate.svg';

const libraries = ['places'];

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: -33.456928,
  lng: -70.6396884,
};

function Locate({ panTo }) {
  function setCenter() {
    navigator.geolocation.getCurrentPosition((position) => {
      panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }

  return (
    <button type="button" className="locate" onClick={setCenter}>
      <img src={locate} alt="Locate" />
    </button>
  );
}

function PuntoPickup() {
  const history = useHistory();
  const { hash } = useParams();
  const [order, setOrder] = useState(null);
  const [containerStyle, setContainerStyle] = useState({
    width: '100%',
    height: '500px',
  });
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [comunas, setComunas] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
  });

  useEffect(() => {
    api.get(`/get-autorizacion-data/${hash}`).then((res) => {
      setOrder(res.data.data);
      const headerHeight = document.getElementById('header').clientHeight;
      const headerPageHeight = document.getElementById(
        'header-punto-pickup'
      ).clientHeight;
      const footerHeight = document.getElementById('footer').clientHeight;

      setContainerStyle({
        width: '100%',
        height: `calc(100vh - (${headerHeight}px + ${headerPageHeight}px + ${footerHeight}px))`,
      });
    });
  }, []);

  useEffect(() => {
    api.get('/get-puntos-pickup/').then((res) => {
      setMarkers(res.data.data);
    });
    api.get('/bx-geo/districts/').then((res) => {
      setComunas(res.data.data.Districts);
    });
  }, []);

  useEffect(() => {
    api.get(`/get-puntos-pickup/${filter}`).then((res) => {
      setMarkers(res.data.data);
    });
  }, [filter]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  const mapRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
  });

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    navigator.geolocation.getCurrentPosition((position) => {
      panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  function handleClickVolver() {
    history.goBack();
  }

  function handleClickMarker(marker) {
    setSelected(marker);
    panTo({
      lat: Number(marker.coordenadas.latitud),
      lng: Number(marker.coordenadas.longitud),
    });
    mapRef.current.setZoom(15);
    open();
  }

  function handleClickModalClose() {
    setSelected(null);
    close();
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return 'Loading Maps';
  }

  return (
    <>
      <Container>
        <div id="header-punto-pickup" className="max-w-2xl mx-auto pt-5">
          <button
            type="button"
            className="btn-link mb-5"
            onClick={handleClickVolver}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Volver atrás
          </button>
          <h2 className="text-center mb-2">Autorización de devolución</h2>
          <h4 className="text-center mb-5">
            OS <span className="text-orange">{order?.tracking}</span>
          </h4>
          <p className="text-center">
            Puedes elegir el punto Pickup que más te acomode, recuerda que si
            eliges un Pickup y deseas ir a otro lo podrás hacer sin
            inconvenientes.
          </p>
        </div>
      </Container>

      {isLoaded && center && (
        <div className="relative overflow-hidden">
          <Locate panTo={panTo} />

          <div className="autocomplete-search">
            <Autocomplete
              getItemValue={(item) => item.distritosName}
              items={comunas}
              renderInput={(props) => (
                <input
                  type="text"
                  className="input-borded rounded-2xl"
                  placeholder="Buscar comuna..."
                  {...props}
                />
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                >
                  {item.distritosName}
                </div>
              )}
              shouldItemRender={(item) =>
                item.distritosName.toLowerCase().indexOf(search.toLowerCase()) >
                -1
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSelect={(val) => {
                setSearch(val);
                setFilter(val);
              }}
            />
          </div>

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            options={options}
            onLoad={onMapLoad}
          >
            {markers?.map((marker, idx) => (
              <Marker
                key={idx}
                position={{
                  lat: Number(marker.coordenadas.latitud),
                  lng: Number(marker.coordenadas.longitud),
                }}
                onClick={() => handleClickMarker(marker)}
                icon={{
                  url: dotBlue,
                  scaledSize: new window.google.maps.Size(26, 26),
                }}
              />
            ))}
          </GoogleMap>

          <div className="flex-1 sm:overflow-x-hidden">
            <div
              className="flex flex-col sm:flex-row sm:flex-nowrap sm:absolute overflow-y-auto p-2 max-h-60 sm:mr-16"
              style={{ bottom: '0.5rem' }}
            >
              {markers?.map((marker, index) => (
                <div
                  key={`punto-${index}`}
                  className="shadow-md flex-shrink-0 mr-3 flex flex-row items-center bg-white rounded-2xl px-4 py-2 cursor-pointer mb-2"
                  onClick={() => handleClickMarker(marker)}
                  aria-hidden="true"
                >
                  <div className="mr-2">
                    <img
                      className="w-20 h-20"
                      src={puntosIcon}
                      alt="Punto Pickup"
                    />
                  </div>
                  <div className="text-left flex-1">
                    <h4>{marker.Direccion}</h4>
                    <small>
                      Lun - Viernes {marker['Lun-Vie']}
                      {marker.Sab !== 'cerrado' && ` | Sab ${marker.Sab}`}
                      {marker.Dom !== 'cerrado' && ` | Dom ${marker.Dom}`}
                    </small>
                    <div className="flex flex-row">
                      {/* <h4 className="text-orange pr-1">
                        <small>Abierto</small>
                      </h4>
                      <span className="font-extrabold">|</span> */}
                      {/* <h4 className="pl-1">
                        <small>1,3 Km</small>
                      </h4> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Modal>
        {selected && (
          <div className="w-72 p-5 bg-white rounded-2xl">
            <div className="flex justify-end mb-2">
              <button type="button" onClick={handleClickModalClose}>
                <img src={closeIcon} alt="Cerrar" />
              </button>
            </div>
            <div className="flex flex-col justify-center items-center mb-5">
              <div className="mb-2">
                <img src={puntosIcon} alt={selected.name} />
              </div>
              <h2 className="text-center text-xl font-bold">{selected.name}</h2>
              {/* {selected.situacion === 1 ? (
                <p className="text-green">Abierto</p>
              ) : (
                <p className="text-orange">Cerrado</p>
              )} */}
            </div>
            <div className="mb-2">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '30px',
                        verticalAlign: 'initial',
                        paddingTop: '5px',
                      }}
                    >
                      <img src={clock} alt="Hora" />
                    </td>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td className="font-bold">Hora</td>
                          </tr>
                          {selected['Lun-Vie'] && (
                            <tr>
                              <td>
                                <small>
                                  Lun - Viernes {selected['Lun-Vie']}
                                </small>
                              </td>
                            </tr>
                          )}
                          {selected['Sab-y-Dom'] && (
                            <tr>
                              <td>
                                <small>
                                  Sab - Domingo {selected['Sab-y-Dom']}
                                </small>
                              </td>
                            </tr>
                          )}
                          {selected.Sab && (
                            <tr>
                              <td>
                                <small>Sabado {selected.Sab}</small>
                              </td>
                            </tr>
                          )}
                          {selected.Dom && (
                            <tr>
                              <td>
                                <small>Domingo {selected.Dom}</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mb-2">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '30px',
                        verticalAlign: 'initial',
                        paddingTop: '5px',
                      }}
                    >
                      <img src={phone} alt="Teléfono" />
                    </td>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td className="font-bold">Teléfono</td>
                          </tr>
                          <tr>
                            <td>
                              <small>600 6000 115</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mb-5 py-2">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '30px',
                        verticalAlign: 'initial',
                        paddingTop: '5px',
                      }}
                    >
                      <img src={markerIcon} alt="Dirección" />
                    </td>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td className="font-bold">Dirección</td>
                          </tr>
                          <tr>
                            <td>
                              <small>{selected.Direccion}</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="flex flex-row justify-center items-center divide-x">
              <button
                type="button"
                className="flex-1 btn-link flex flex-row justify-center items-center"
              >
                <img src={markerBlue} alt="Dirección" className="mr-1" />
                <small>¿Cómo llegar?</small>
              </button>
              <a
                href="tel:6006000115"
                className="flex-1 btn-link flex flex-row justify-center items-center"
              >
                <img src={phoneBlue} alt="Teléfono" className="mr-1" />
                <small>Llamar</small>
              </a>
            </div> */}
            <div className="flex flex-row justify-center items-center divide-x">
              <a
                href="https://blue.cl"
                className="flex-1 flex flex-row justify-center items-center btn-primary"
              >
                {/* <img src={phoneBlue} alt="Teléfono" className="mr-1" /> */}
                Ir a blue.cl
              </a>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default PuntoPickup;
