/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createRutMask from 'text-mask-rut';
import ImageUploading from 'react-images-uploading';
import useModal from 'react-hooks-use-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Container from '../components/Container';
import Accordion from '../components/Accordion';

import api from '../services/api';

import { ReactComponent as FormIcon } from '../assets/img/form-checked.svg';
import searchIcon from '../assets/img/search.svg';
import locationIcon from '../assets/img/icon-location.svg';
import addCircle from '../assets/img/add-circle.svg';
import remove from '../assets/img/remove.svg';
import closeIcon from '../assets/img/close.png';
import wow from '../assets/img/wow.svg';

import regions from '../utils/states.json';

const searchOptions = {
  componentRestrictions: {
    country: 'cl',
  },
};

const phoneOptions = [
  { value: '+569', label: '+569' },
  { value: '+562', label: '+562' },
];

const phoneMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

function ProgramacionDelRetiroNew() {
  const history = useHistory();
  const { hash } = useParams();
  const searchInputRef = useRef(null);
  const numeroInputRef = useRef(null);
  const rutMask = createRutMask();

  const [order, setOrder] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [stepDireccionIsValid, setStepDireccionIsValid] = useState(false);
  const [stepFechaIsValid, setStepFechaIsValid] = useState(false);
  const [stepTerceroIsValid, setStepTerceroIsValid] = useState(false);
  const [stepProductImgIsValid, setStepProductImgIsValid] = useState(false);
  const [ingresarDireccion, setIngresarDireccion] = useState(true);
  // const [direccionIsValid, setDireccionIsValid] = useState(false);

  const [region, setRegion] = useState('');
  // const [ciudad, setCiudad] = useState('');
  const [comuna, setComuna] = useState('');
  const [calle, setCalle] = useState('');
  const [numero, setNumero] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [codTelefono, setCodTelefono] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mail, setMail] = useState('');
  const [rut, setRut] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [comentario, setComentario] = useState('');

  const [nombreContacto, setNombreContacto] = useState('');
  const [codTelefonoContacto, setCodTelefonoContacto] = useState('');
  const [telefonoContacto, setTelefonoContacto] = useState('');
  const [rutContacto, setRutContacto] = useState('');

  const [productImages, setProductImages] = useState('');

  // const [ciudades, setCiudades] = useState([]);
  const [comunas, setComunas] = useState([]);

  const [regionsOptions, setRegionsOptions] = useState([]);
  // const [ciudadesOptions, setCiudadesOptions] = useState([]);
  const [comunasOptions, setComunasOptions] = useState([]);

  const [regionCode, setRegionCode] = useState(0);
  // const [ciudadCode, setCiudadCode] = useState(0);
  const [, setComunaCode] = useState(0);

  const [fechasOptions, setFechasOptions] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [planificacionSelected, setPlanificacionSelected] = useState(null);

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
  });
  const [ModalMaxImg, openModalMaxImg, closeModalMaxImg] = useModal('root', {
    preventScroll: true,
  });
  const [
    ModalErrorImgUploading,
    openModalErrorImgUploading,
    closeModalErrorImgUploading,
  ] = useModal('root', {
    preventScroll: true,
  });
  const [ModalAcceptType, openModalAcceptType, closeModalAcceptType] = useModal(
    'root',
    {
      preventScroll: true,
    }
  );

  useEffect(() => {
    const regionsOpt = regions.map((item) => ({
      value: item.name.toUpperCase(),
      label: item.name.toUpperCase(),
    }));
    setRegionsOptions(regionsOpt);

    api
      .get(`/get-autorizacion-data/${hash}`)
      .then(async (res) => {
        setOrder(res.data.data);

        if (
          res.data?.data?.timesUpdated >= res.data?.data?.config?.qtdCancel ||
          res.data?.data?.status === 'canceled'
        ) {
          history.push(`/punto-pickup/${hash}`);
        }

        const resultPlanificacion = await api.get(
          `/planificacion-retiro/${hash}`
        );
        setPlanificacion(resultPlanificacion.data.data.fechas);
        const fechas = resultPlanificacion.data.data.fechas.map(
          (item, index) => ({
            value: index,
            label: `${item.fecha}, entre las ${item.horaIni} y ${item.horaFim}`,
          })
        );
        setFechasOptions(fechas);

        const state = res.data.data.state?.toUpperCase() || '';
        // const city = res.data.data.ciudad?.toUpperCase() || '';
        const district = res.data.data.district?.toUpperCase() || '';
        const street = res.data.data.street?.toUpperCase() || '';
        const number = res.data.data.number || '';

        setRegion(state);
        // setCiudad(city);
        setComuna(district);
        setCalle(street);
        setNumero(number);

        // geocodeByAddress(
        //   `${number} ${street}, ${district}, ${city}, ${state}`
        // ).then((response) => {
        //   console.log('response', response);

        //   response[0].address_components.forEach((addrItem) => {
        //     if (
        //       addrItem.types.includes('route') ||
        //       addrItem.types.includes('street_address')
        //     ) {
        //       if (addrItem.long_name.toUpperCase() === street) {
        //         // setDireccionIsValid(true);
        //         setIngresarDireccion(false);
        //       }
        //     }
        //   });
        // });

        setDepartamento(res.data.data.complement || '');

        if (res.data.data.phone?.length > 0) {
          if (res.data.data.phone.length === 10) {
            res.data.data.phone = `+${res.data.data.phone}`;
          }
          if (res.data.data.phone.length > 9) {
            setCodTelefono(res.data.data.phone.substring(0, 4));
            setTelefono(
              `${res.data.data.phone.substring(
                4,
                8
              )} ${res.data.data.phone.substring(8)}`
            );
          } else {
            setTelefono(res.data.data.phone);
          }
        }
        setMail(res.data.data.mail || '');
        setComentario(res.data.data.comment || '');
        setRut(res.data.data.rut || '');
        setDescripcion(res.data.data.description || '');

        setNombreContacto(res.data.data.contactName);
        if (res.data.data.contactPhone?.length > 0) {
          if (res.data.data.contactPhone.length === 10) {
            res.data.data.contactPhone = `+${res.data.data.contactPhone}`;
          }
          if (res.data.data.contactPhone.length > 9) {
            setCodTelefonoContacto(res.data.data.contactPhone.substring(0, 4));
            setTelefonoContacto(
              `${res.data.data.contactPhone.substring(
                4,
                8
              )} ${res.data.data.contactPhone.substring(8)}`
            );
          } else {
            setTelefonoContacto(res.data.data.contactPhone);
          }
        }
        setRutContacto(res.data.data.contactRut || '');

        const prodImages = res.data.data.productImages?.map((img) => ({
          base64: img.base64,
          file: { name: img.nome },
        }));
        setProductImages(prodImages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (region.length > 0) {
      const regionSelected = regions.find(
        (item) => item.name.toUpperCase() === region.toUpperCase()
      );
      setRegionCode(regionSelected.code);
    } else {
      setRegionCode(0);
    }
  }, [region]);

  useEffect(() => {
    // async function getCiudades(code) {
    //   console.log(code);
    //   try {
    //     const response = await api.get(
    //       `/bx-geo/ciudades/${code.toString().padStart(2, '0')}`
    //     );
    //     const cities = response.data.data.ciudades;
    //     setCiudades(cities);
    //     const optionsCities = cities.map((item) => ({
    //       value: item.name.toUpperCase(),
    //       label: item.name.toUpperCase(),
    //     }));
    //     setCiudadesOptions(optionsCities);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    // if (regionCode !== 0) {
    //   getCiudades(regionCode);
    // }

    async function getComunas(code) {
      try {
        const response = await api.get(
          `/bx-geo/districts/${code.toString().padStart(2, '0')}`
        );
        const districts = response.data.data.Districts;

        setComunas(districts);

        const optionsDistricts = districts.map((item) => ({
          value: item.distritosName.toUpperCase(),
          label: item.distritosName.toUpperCase(),
        }));

        setComunasOptions(optionsDistricts);
      } catch (err) {
        console.log(err);
      }
    }

    if (regionCode !== 0) {
      getComunas(regionCode);
    }
  }, [regionCode]);

  // useEffect(() => {
  //   if (ciudad.length > 0 && ciudades.length > 0) {
  //     const ciudadSelected = ciudades.find(
  //       (item) => item.name.toUpperCase() === ciudad.toUpperCase()
  //     );

  //     if (ciudadSelected) {
  //       setCiudadCode(ciudadSelected.code);
  //     } else {
  //       setCiudad('');
  //       setCiudadCode(0);
  //     }
  //   } else {
  //     setCiudadCode(0);
  //   }
  // }, [ciudad, ciudades]);

  // useEffect(() => {
  //   async function getComunas(code) {
  //     try {
  //       const response = await api.get(
  //         `/bx-geo/districts/${code.toString().padStart(3, '0')}`
  //       );
  //       const districts = response.data.data.Districts;

  //       setComunas(districts);

  //       const optionsDistricts = districts.map((item) => ({
  //         value: item.distritosName.toUpperCase(),
  //         label: item.distritosName.toUpperCase(),
  //       }));

  //       setComunasOptions(optionsDistricts);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  //   if (ciudadCode !== 0) {
  //     getComunas(ciudadCode);
  //   }
  // }, [ciudadCode]);

  useEffect(() => {
    if (comuna.length > 0 && comunas.length > 0) {
      const comunaSelected = comunas.find(
        (item) => item.distritosName.toUpperCase() === comuna.toUpperCase()
      );

      if (comunaSelected) {
        setComunaCode(comunaSelected.distritosCode);
      } else {
        setComuna('');
        setComunaCode(0);
      }
    } else {
      setComunaCode(0);
    }
  }, [comuna, comunas]);

  useEffect(() => {
    const isValid =
      region?.length > 0 &&
      // ciudad?.length > 0 &&
      comuna?.length > 0 &&
      calle?.length > 0 &&
      codTelefono?.length > 0 &&
      telefono?.length > 0 &&
      mail?.length > 0 &&
      rut?.length > 0;
    // && direccionIsValid;

    setStepDireccionIsValid(isValid);
  }, [
    ingresarDireccion,
    region,
    // ciudad,
    comuna,
    calle,
    codTelefono,
    telefono,
    mail,
    rut,
    // direccionIsValid,
  ]);

  useEffect(() => {
    const isValid = planificacionSelected !== null;
    setStepFechaIsValid(isValid);
  }, [planificacionSelected]);

  useEffect(() => {
    let isValid = true;

    if (
      nombreContacto?.length > 0 ||
      telefonoContacto?.length > 0 ||
      rutContacto?.length > 0
    ) {
      isValid =
        nombreContacto?.length > 0 &&
        telefonoContacto?.length > 0 &&
        rutContacto?.length > 0;
    }

    setStepTerceroIsValid(isValid);
  }, [nombreContacto, telefonoContacto, rutContacto]);

  useEffect(() => {
    const isValid = productImages?.length > 0;
    setStepProductImgIsValid(isValid);
  }, [productImages]);

  function handleClickExpand(index) {
    setActiveIndex(index);
  }

  function handleChangeDireccion(value) {
    setCalle(value);
    // setDireccionIsValid(false);
  }

  function setDireccionForm(values) {
    console.log(values);

    values[0].address_components.forEach((item) => {
      // console.log(item);
      // if (item.types.includes('administrative_area_level_1')) {
      //   setRegion(item.long_name);
      // }
      // if (item.types.includes('administrative_area_level_2')) {
      //   setCiudad(item.long_name);
      // }
      // if (
      //   item.types.includes('sublocality') ||
      //   item.types.includes('locality')
      // ) {
      //   setComuna(item.long_name);
      // }
      if (item.types.includes('route')) {
        setCalle(item.long_name);
        // setDireccionIsValid(true);
      }
      if (item.types.includes('street_number')) {
        setNumero(item.long_name);
      }
      numeroInputRef.current.focus();
    });
  }

  function handleSelectAddress(addr) {
    // setSearch('');
    geocodeByAddress(addr)
      .then((results) => {
        setDireccionForm(results);
        getLatLng(results[0]);
      })
      // .then((latLng) => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  }

  function handleClickVolverDireccion() {
    setIngresarDireccion(false);
    setRegion(order.state);
    // setCiudad(order.ciudad);
    setComuna(order.district);
    setCalle(order.street);
    setNumero(order.number);
    setDepartamento(order.complement);
    setTelefono(order.phone);
    setMail(order.mail);
    setDescripcion(order.description);
    setComentario(order.comment);
  }

  function handleClickIngresarDireccion() {
    setIngresarDireccion(true);
  }

  function handleChangeRegion(e) {
    setRegion(e.target.value);
  }

  // function handleChangeCiudad(e) {
  //   setCiudad(e.target.value);
  // }

  function handleChangeComuna(e) {
    setComuna(e.target.value);
  }

  function handleChangeCodTelefono(e) {
    setCodTelefono(e.target.value);
  }

  function handleSelectFecha(value) {
    setPlanificacionSelected(planificacion[value]);
  }

  function handleChangeCodTelefonoContacto(e) {
    setCodTelefonoContacto(e.target.value);
  }

  const maxNumber = 10;

  const onChangeProductImages = (imageList /* , addUpdateIndex */) => {
    console.log(imageList);
    setProductImages(imageList);
  };

  function handleErrorImageUploading(error) {
    // console.log(error);
    if (error.maxNumber) {
      openModalMaxImg(true);
    } else if (error.acceptType) {
      openModalAcceptType(true);
    } else {
      openModalErrorImgUploading(true);
    }
  }

  function handleClickVolver() {
    history.goBack();
  }

  function handleCloseModal() {
    close();
    window.location.href = 'https://www.blue.cl';
  }

  function handleSubmit(event) {
    event.preventDefault();

    // const prodImages = productImages?.map((img) => ({
    //   base64: img.base64,
    //   nome: img.file.name,
    // }));

    let phoneNumbers = '';
    if (codTelefono?.length > 0 && telefono?.length > 0) {
      phoneNumbers = `${codTelefono}${telefono}`.match(/\d+/g).join('');
    }

    let phoneNumbersContacto = '';
    if (codTelefonoContacto?.length > 0 && telefonoContacto?.length > 0) {
      phoneNumbersContacto = `${codTelefonoContacto}${telefonoContacto}`
        .match(/\d+/g)
        .join('');
    }

    const data = {
      dados: {
        id: hash,
        ordem: order.id,
        client: order.client,
        tipoRetiro: 'Retiro',
        withdrawalDate: planificacionSelected.fecha,
        withdrawalTimeInit: planificacionSelected.horaIni,
        withdrawalTimeEnd: planificacionSelected.horaFim,
        state: region,
        // ciudad,
        district: comuna,
        street: calle,
        number: numero,
        complement: departamento,
        phone: `+${phoneNumbers}`,
        mail,
        comment: comentario,
        rut,
        description: descripcion,
        contactName: nombreContacto,
        contactPhone: `+${phoneNumbersContacto}`,
        contactRut: rutContacto,
        // productImages: prodImages,
        // estadoCode: regionCode,
        // ciudadCode,
        // distritoCode: comunaCode,
      },
    };

    // const data = new FormData();
    // data.append('id', hash);
    // data.append('ordem', order.id);
    // data.append('client', order.client);
    // data.append('tipoRetiro', 'Retiro');
    // data.append('withdrawalDate', planificacionSelected.fecha);
    // data.append('withdrawalTimeInit', planificacionSelected.horaIni);
    // data.append('withdrawalTimeEnd', planificacionSelected.horaFim);
    // data.append('state', region);
    // data.append('district', comuna);
    // data.append('street', calle);
    // data.append('number', numero);
    // data.append('complement', departamento);
    // data.append('phone', `+${phoneNumbers}`);
    // data.append('mail', mail);
    // data.append('comment', comentario);
    // data.append('rut', rut);
    // data.append('description', descripcion);
    // data.append('contactName', nombreContacto);
    // data.append('contactPhone', `+${phoneNumbersContacto}`);
    // data.append('contactRut', rutContacto);
    // // data.append('estadoCode', regionCode);
    // // data.append('distritoCode', comunaCode);
    // data.append('productImages', productImages);

    api
      .post(
        `/alertadeprogramacion/`,
        data
        // {
        //   headers: {
        //     'Content-type': 'multipart/form-data',
        //   },
        // }
      )
      .then(() => {
        open();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Container>
      <div className="max-w-2xl mx-auto">
        <div className="mb-5">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl text-center mb-5">
              Autorización de devolución
            </h1>
            <FormIcon />
            <p className="text-center text-lg font-bold mt-5">
              OS <span className="text-orange">{order?.tracking}</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="bg-white rounded-2xl overflow-hidden shadow-md">
            <div className="flex flex-col divide-y">
              <Accordion
                title={<strong>Dirección de retiro</strong>}
                isExpanded={activeIndex === 1}
                onCollapse={() => handleClickExpand(0)}
                onExpand={() => handleClickExpand(1)}
                isValid={stepDireccionIsValid}
              >
                <div className="flex flex-col justify-center items-center p-5 bg-gray-50">
                  <div className="w-full">
                    <div hidden={!ingresarDireccion}>
                      <div>
                        <button
                          type="button"
                          className="w-full text-right btn-link text-sm mt-2 pr-10"
                          onClick={handleClickVolverDireccion}
                        >
                          Volver dirección
                        </button>
                      </div>
                    </div>
                    <div hidden={ingresarDireccion}>
                      <button
                        type="button"
                        className="btn-primary w-full flex flex-row items-center justify-center"
                        onClick={handleClickIngresarDireccion}
                      >
                        <img
                          src={locationIcon}
                          alt="Dirección"
                          className="mr-2"
                        />{' '}
                        Ingrese otra dirección
                      </button>
                    </div>
                    <div
                      className={classNames('mt-10', {
                        'opacity-50': !ingresarDireccion,
                      })}
                    >
                      <div className="mb-8">
                        <label htmlFor="region">
                          Region<span className="text-orange">*</span>:
                          <select
                            name="region"
                            className="mt-3 block w-full input-borded rounded-2xl mr-2"
                            onChange={handleChangeRegion}
                            value={region}
                            disabled={!ingresarDireccion}
                          >
                            <option label="Introduce la region" />
                            {regionsOptions.map((opt, index) => (
                              <option
                                key={`estado-${index}`}
                                value={opt.value}
                                label={opt.label}
                              />
                            ))}
                          </select>
                        </label>
                      </div>
                      {/* <div className="mb-8">
                        <label htmlFor="ciudad">
                          Ciudad<span className="text-orange">*</span>:
                          <select
                            name="ciudad"
                            className="mt-3 block w-full input-borded rounded-2xl mr-2"
                            onChange={handleChangeCiudad}
                            value={ciudad}
                            disabled={!ingresarDireccion}
                          >
                            <option label="Introduce la ciudad" />
                            {ciudadesOptions.map((opt, index) => (
                              <option
                                key={`ciudad-${index}`}
                                value={opt.value}
                                label={opt.label}
                              />
                            ))}
                          </select>
                        </label>
                      </div> */}
                      <div className="mb-8">
                        <label htmlFor="comuna">
                          Comuna<span className="text-orange">*</span>:
                          <select
                            name="comuna"
                            className="mt-3 block w-full input-borded rounded-2xl mr-2"
                            onChange={handleChangeComuna}
                            value={comuna}
                            disabled={!ingresarDireccion}
                          >
                            <option label="Introduce la comuna" />
                            {comunasOptions.map((opt, index) => (
                              <option
                                key={`comuna-${index}`}
                                value={opt.value}
                                label={opt.label}
                              />
                            ))}
                          </select>
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="calle">
                          Calle<span className="text-orange">*</span>:
                          <PlacesAutocomplete
                            value={calle}
                            onChange={handleChangeDireccion}
                            onSelect={handleSelectAddress}
                            searchOptions={searchOptions}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <div className="relative">
                                  <input
                                    {...getInputProps({
                                      ref: searchInputRef,
                                      type: 'text',
                                      placeholder: 'Buscar dirección',
                                      className: classNames(
                                        'mt-3',
                                        'block',
                                        'w-full',
                                        'input-borded',
                                        'rounded-2xl',
                                        'mr-2'
                                      ),
                                      disabled: !ingresarDireccion,
                                    })}
                                  />
                                  <img
                                    src={searchIcon}
                                    alt="Buscar dirección"
                                    style={{
                                      position: 'absolute',
                                      top: '42%',
                                      left: 'auto',
                                      right: '0.7em',
                                      marginTop: '-7px',
                                      color: '#bbc0c4',
                                      verticalAlign: 'bottom',
                                      pointerEvents: 'none',
                                    }}
                                  />
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Buscando...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    console.log(suggestion);
                                    if (suggestion.types.includes('route')) {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="numero">
                          Número:
                          <input
                            ref={numeroInputRef}
                            type="text"
                            id="numero"
                            name="numero"
                            className="mt-3 block w-full input-borded rounded-2xl"
                            placeholder="Introduce el número"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                            disabled={!ingresarDireccion}
                          />
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="departamento">
                          Departamento:
                          <input
                            type="text"
                            id="departamento"
                            name="departamento"
                            className="mt-3 block w-full input-borded rounded-2xl"
                            placeholder="Introduce el departamento"
                            value={departamento}
                            onChange={(e) => setDepartamento(e.target.value)}
                            disabled={!ingresarDireccion}
                          />
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="telefono" className="block">
                          Teléfono<span className="text-orange">*</span>:
                          <div className="flex mt-3">
                            <select
                              className="block input-borded rounded-2xl mr-2"
                              onChange={handleChangeCodTelefono}
                              value={codTelefono}
                              disabled={!ingresarDireccion}
                            >
                              <option label="Cód." />
                              {phoneOptions.map((opt, index) => (
                                <option
                                  key={`cod-telefono-${index}`}
                                  value={opt.value}
                                  label={opt.label}
                                />
                              ))}
                            </select>
                            <MaskedInput
                              id="telefono"
                              className="w-full input-borded rounded-2xl px-3 py-2 focus:outline-none"
                              guide={false}
                              placeholder="Ex: 2552 1383"
                              mask={phoneMask}
                              disabled={!ingresarDireccion}
                              value={telefono}
                              onChange={(e) => setTelefono(e.target.value)}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="mail">
                          Email<span className="text-orange">*</span>:
                          <input
                            type="email"
                            id="mail"
                            name="mail"
                            className="mt-3 block w-full input-borded rounded-2xl"
                            placeholder="Introduce el email"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                            disabled={!ingresarDireccion}
                          />
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="rut">
                          RUT<span className="text-orange">*</span>:
                          <MaskedInput
                            id="rut"
                            className="w-full input-borded rounded-2xl px-3 py-2 mt-3 focus:outline-none"
                            guide={false}
                            placeholder="Ingrese el RUT"
                            mask={rutMask}
                            value={rut}
                            onChange={(e) => setRut(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="descripcion">
                          Descripción:
                          <input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            className="mt-3 block w-full input-borded rounded-2xl"
                            placeholder="Introduce la descripción"
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                            disabled={!ingresarDireccion}
                          />
                        </label>
                      </div>
                      <div className="mb-8">
                        <label htmlFor="comentario">
                          Deja un comentario:
                          <textarea
                            id="comentario"
                            name="comentario"
                            maxLength="256"
                            rows={4}
                            className="mt-3 block w-full input-borded rounded-2xl"
                            placeholder="Introduce un comentario"
                            value={comentario}
                            onChange={(e) => setComentario(e.target.value)}
                            disabled={!ingresarDireccion}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion>

              <Accordion
                title={
                  <>
                    <strong>Selecciona el día y la hora para el retiro</strong>{' '}
                    <small>(en un rango de 48 horas)</small>
                  </>
                }
                isExpanded={activeIndex === 2}
                onCollapse={() => handleClickExpand(0)}
                onExpand={() => handleClickExpand(2)}
                isValid={stepFechaIsValid}
              >
                <div className="flex flex-col justify-center items-center p-5 bg-gray-50">
                  <div className="font-bold mb-5">
                    Elija una de estas opciones
                  </div>
                  <div className="text-center w-full md:w-3/5">
                    {fechasOptions.map((opt, index) => (
                      <button
                        key={`fecha-${index}`}
                        type="button"
                        className={classNames(
                          'px-10',
                          'py-2',
                          'mb-2',
                          'rounded-full',
                          {
                            'btn-blue-default':
                              planificacionSelected !==
                              planificacion[opt.value],
                          },
                          {
                            'btn-blue-active':
                              planificacionSelected ===
                              planificacion[opt.value],
                          }
                        )}
                        onClick={() => handleSelectFecha(opt.value)}
                      >
                        {opt.label}
                      </button>
                    ))}
                  </div>
                </div>
              </Accordion>

              <Accordion
                title={
                  <strong>
                    ¿La devolución será entregada por otra persona?
                  </strong>
                }
                isExpanded={activeIndex === 3}
                onCollapse={() => handleClickExpand(0)}
                onExpand={() => handleClickExpand(3)}
                isValid={stepTerceroIsValid}
              >
                <div
                  className="
                      flex
                      flex-col
                      justify-center
                      content-center
                      items-center
                      p-5
                      bg-gray-50"
                >
                  <div className="w-full">
                    <div className="mb-8 font-bold">
                      Ingrese la información de contacto de terceros
                    </div>
                    <div className="mb-8">
                      <label htmlFor="nombre_contacto">
                        Nombre Contacto:
                        <input
                          type="text"
                          id="nombre_contacto"
                          name="nombre_contacto"
                          className="block mt-3 w-full input-borded rounded-2xl"
                          placeholder="Ingrese el contacto"
                          value={nombreContacto}
                          onChange={(e) => setNombreContacto(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="mb-8">
                      <label htmlFor="telefono_contacto" className="block">
                        Teléfono Contacto:
                        <div className="flex mt-3">
                          <select
                            className="block input-borded rounded-2xl mr-2"
                            onChange={handleChangeCodTelefonoContacto}
                            value={codTelefonoContacto}
                          >
                            <option label="Cod." />
                            {phoneOptions.map((opt, index) => (
                              <option
                                key={`cod-telefono-contacto-${index}`}
                                value={opt.value}
                                label={opt.label}
                              />
                            ))}
                          </select>
                          <MaskedInput
                            id="telefono_contacto"
                            className="w-full input-borded rounded-2xl px-3 py-2 focus:outline-none"
                            guide={false}
                            placeholder="Ex: 2552 1383"
                            mask={phoneMask}
                            value={telefonoContacto}
                            onChange={(e) =>
                              setTelefonoContacto(e.target.value)
                            }
                          />
                        </div>
                      </label>
                    </div>
                    <div className="mb-8">
                      <label htmlFor="rut_contacto">
                        RUT Contacto:
                        <MaskedInput
                          id="rut_contacto"
                          className="w-full input-borded rounded-2xl px-3 py-2 mt-3 focus:outline-none"
                          guide={false}
                          placeholder="Ingrese el RUT"
                          mask={rutMask}
                          value={rutContacto}
                          onChange={(e) => setRutContacto(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </Accordion>

              <Accordion
                title={
                  <>
                    <strong>Enviar foto de producto que será devuelto</strong>{' '}
                    <small>(hasta 10 fotos)</small>
                  </>
                }
                isExpanded={activeIndex === 5}
                onCollapse={() => handleClickExpand(0)}
                onExpand={() => handleClickExpand(5)}
                isValid={stepProductImgIsValid}
              >
                <div className="w-full p-5 bg-gray-50">
                  <div
                    className="
                      flex
                      flex-col
                      justify-center
                      content-center
                      items-center
                      bg-white
                      rounded-2xl
                      shadow-md
                      p-5"
                  >
                    <ImageUploading
                      multiple
                      value={productImages}
                      onChange={onChangeProductImages}
                      maxNumber={maxNumber}
                      dataURLKey="base64"
                      onError={handleErrorImageUploading}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <>
                          <div className="flex flex-row justify-between flex-wrap mb-5">
                            {imageList.map((image, index) => (
                              <div key={index}>
                                <div>
                                  <button
                                    type="button"
                                    className="absolute ml-16"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <img src={remove} alt="Remove" />
                                  </button>
                                </div>
                                <div>
                                  <img
                                    src={image.base64}
                                    className="object-cover rounded-full w-20 h-20 shadow-sm m-1 border"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="mb-5">
                            Toma foto o inserta archivo.
                          </div>
                          <div className="flex flex-col justify-center items-center w-full py-5 border-dashed border-2 border-default-blue">
                            <button
                              type="button"
                              style={isDragging ? { color: 'red' } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                className="h-16 w-auto"
                                src={addCircle}
                                alt="Agregar imagen"
                              />
                            </button>
                            <p className="text-center text-sm mt-5">
                              Haz clic y agrega la imagen
                            </p>
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>

          <div className="mt-5 text-center">
            <button
              type="submit"
              className="btn-primary w-32"
              disabled={!stepFechaIsValid || !stepDireccionIsValid}
            >
              Enviar
            </button>
          </div>
        </form>

        <Modal>
          <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
            <div className="w-full text-right p-5">
              <button
                type="button"
                className="bg-transparent"
                onClick={handleCloseModal}
              >
                <img src={closeIcon} alt="Cerrar" />
              </button>
            </div>
            <div className="flex flex-col justify-between items-center p-8">
              <img className="mb-2" src={wow} alt="WOW" />
              <h1 className="text-xl font-black mb-5">WOW!</h1>
              <p className="text-center text-lg font-bold">
                Tu solicitud está siendo revisada y en unos minutos estarás
                recibiendo la confirmación de tu retiro
              </p>
            </div>
          </div>
        </Modal>

        <ModalMaxImg>
          <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
            <div className="w-full text-right p-5">
              <button
                type="button"
                className="bg-transparent"
                onClick={closeModalMaxImg}
              >
                <img src={closeIcon} alt="Cerrar" />
              </button>
            </div>
            <div className="flex flex-col justify-between items-center p-8">
              <h1 className="text-xl text-orange mb-5">¡Atención!</h1>
              <p className="text-center text-lg font-bold">
                ¡Solo puedes adjuntar hasta 10 fotos!
              </p>
            </div>
          </div>
        </ModalMaxImg>

        <ModalErrorImgUploading>
          <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
            <div className="w-full text-right p-5">
              <button
                type="button"
                className="bg-transparent"
                onClick={closeModalErrorImgUploading}
              >
                <img src={closeIcon} alt="Cerrar" />
              </button>
            </div>
            <div className="flex flex-col justify-between items-center p-8">
              <h1 className="text-xl text-orange mb-5">¡Atención!</h1>
              <p className="text-center text-lg font-bold">
                ¡No se puede cargar la imagen, inténtelo de nuevo!
              </p>
            </div>
          </div>
        </ModalErrorImgUploading>

        <ModalAcceptType>
          <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
            <div className="w-full text-right p-5">
              <button
                type="button"
                className="bg-transparent"
                onClick={closeModalAcceptType}
              >
                <img src={closeIcon} alt="Cerrar" />
              </button>
            </div>
            <div className="flex flex-col justify-between items-center p-8">
              <h1 className="text-xl text-orange mb-5">¡Atención!</h1>
              <p className="text-center text-lg font-bold">
                ¡Seleccionar solo archivos de imagen!
              </p>
            </div>
          </div>
        </ModalAcceptType>

        <div className="mt-5 text-center">
          <button
            type="button"
            className="btn-link"
            onClick={handleClickVolver}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Volver atrás
          </button>
        </div>
      </div>
    </Container>
  );
}

export default ProgramacionDelRetiroNew;
