import React from 'react';

function Footer() {
  return (
    <div
      id="footer"
      className="footer flex items-center justify-center bg-white px-5 py-2"
    >
      <p className="text-center">
        © 2021 Blue Express - Todos los derechos reservados
      </p>
    </div>
  );
}

export default Footer;
