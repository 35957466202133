/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useModal from 'react-hooks-use-modal';
import api from '../services/api';

import Container from '../components/Container';

import closeIcon from '../assets/img/close.png';

function CancelarRetiroOReprogramar() {
  const history = useHistory();
  const { hash } = useParams();
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
  });
  const [
    ModalRetiroCancelado,
    openModalRetiroCancelado,
    closeModalRetiroCancelado,
  ] = useModal('root', {
    preventScroll: true,
  });
  const [
    ModalReprogramacionExcedida,
    openModalReprogramacionExcedida,
    closeModalReprogramacionExcedida,
  ] = useModal('root', {
    preventScroll: true,
  });

  const [order, setOrder] = useState(null);

  useEffect(() => {
    api.get(`/get-autorizacion-data/${hash}`).then((result) => {
      setOrder(result.data.data);
    });
  }, []);

  function handleClickVolver() {
    history.goBack();
  }

  function handleClickReprogramar() {
    if (order?.timesUpdated && order?.timesUpdated >= 2) {
      const data = {
        dados: {
          id: hash,
          ordem: order.id,
          client: order.client,
          mail: order.mail,
          tipoRetiro: order.tipoRetiro,
          withdrawalDate: order.withdrawalDate,
          withdrawalTimeInit: order.withdrawalTimeInit,
          withdrawalTimeEnd: order.withdrawalTimeEnd,
        },
      };

      api
        .post(`/reprogramacionexcedida/`, data)
        .then(() => {
          close();
          openModalReprogramacionExcedida();
        })
        .catch((err) => console.log(err));
    } else {
      history.push(`/programacion-del-retiro/${hash}`);
    }
  }

  function handleClickCancelar() {
    open();
  }

  function handleClickSi() {
    const data = {
      dados: {
        id: hash,
        status: 'canceled',
      },
    };

    api
      .post('/cambiar-estado-envio/', data)
      .then(() => {
        close();
        openModalRetiroCancelado();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleClickNo() {
    close();
  }

  function handleClickPuntosPickup() {
    history.push(`/punto-pickup/${hash}`);
  }

  return (
    <Container>
      <h2 className="text-center my-5">Cancelar o reprogramar retiro</h2>
      <div className="max-w-md mx-auto mt-5 p-5 bg-white rounded-2xl overflow-hidden shadow-md">
        <h4 className="mb-10 ">¿Qué te gustaría hacer con el retiro?</h4>

        <button
          type="button"
          className="btn-primary text-lg mb-4 block w-full"
          onClick={handleClickReprogramar}
        >
          Reprogramar
        </button>
        <button
          type="button"
          className="btn-secondary text-lg mb-4 block w-full"
          onClick={handleClickCancelar}
        >
          Cancelar
        </button>
      </div>
      <div className="mt-5 text-center">
        <button type="button" className="btn-link" onClick={handleClickVolver}>
          <FontAwesomeIcon icon={faArrowLeft} /> Volver atrás
        </button>
      </div>

      <Modal>
        <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl p-8">
          <h4 className="mb-10">¿Deseas cancelar el retiro?</h4>
          <button
            type="button"
            className="btn-primary text-lg mb-4 block w-full"
            onClick={handleClickSi}
          >
            Sí
          </button>
          <button
            type="button"
            className="btn-secondary text-lg mb-4 block w-full"
            onClick={handleClickNo}
          >
            No
          </button>
        </div>
      </Modal>

      <ModalRetiroCancelado>
        <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
          <div className="w-full text-right p-5">
            <button
              type="button"
              className="bg-transparent"
              onClick={closeModalRetiroCancelado}
            >
              <img src={closeIcon} alt="Cerrar" />
            </button>
          </div>
          <div className="flex flex-col justify-between items-center p-8">
            <p className="mb-5">
              Hemos recibido tu solicitud y el retiro ha sido cancelado.
            </p>
            <p className="mb-5">
              Si deseas continuar con tu devolución, puedes llevar tu producto
              al punto Pickup más cercano.
            </p>
            <button
              type="button"
              className="btn-primary text-lg mb-4 block w-full"
              onClick={handleClickPuntosPickup}
            >
              Puntos Pick up
            </button>
          </div>
        </div>
      </ModalRetiroCancelado>

      <ModalReprogramacionExcedida>
        <div className="flex flex-col max-w-sm mx-5 justify-center items-center bg-white rounded-2xl">
          <div className="w-full text-right p-5">
            <button
              type="button"
              className="bg-transparent"
              onClick={closeModalReprogramacionExcedida}
            >
              <img src={closeIcon} alt="Cerrar" />
            </button>
          </div>
          <div className="flex flex-col justify-between items-center p-8">
            <p className="mb-10">
              Has alcanzado el máximo de intentos de reprogramación. Para poder
              continuar con el proceso, debes acercarte al punto Pickup más
              cercano para entregar tu devolución.
            </p>
            <button
              type="button"
              className="btn-primary text-lg mb-4 block w-full"
              onClick={handleClickPuntosPickup}
            >
              Puntos Pick up
            </button>
          </div>
        </div>
      </ModalReprogramacionExcedida>
    </Container>
  );
}

export default CancelarRetiroOReprogramar;
