/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import ImageUploading from 'react-images-uploading';
import useModal from 'react-hooks-use-modal';

import closeIcon from '../assets/img/close.png';
import avatars1 from '../assets/img/avatars-1.png';
import avatars2 from '../assets/img/avatars-2.png';
import avatars3 from '../assets/img/avatars-3.png';
import avatars4 from '../assets/img/avatars-4.png';
import picture from '../assets/img/picture.png';
import editBlue from '../assets/img/edit-blue.png';

function Avatar({ img = 1, rest }) {
  if (img === 1) return <img src={avatars1} alt="Avatar 2" {...rest} />;
  if (img === 2) return <img src={avatars2} alt="Avatar 2" {...rest} />;
  if (img === 3) return <img src={avatars3} alt="Avatar 2" {...rest} />;
  return <img src={avatars4} alt="Avatar 2" {...rest} />;
}

function CourierCheckin() {
  const [avatar, setAvatar] = React.useState(1);
  const [foto, setFoto] = React.useState([]);

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
  });

  function handleClickFoto() {
    open();
  }

  function handleClickModalClose() {
    close();
  }

  function handleClickAvatar(selected) {
    console.log(selected);
    setAvatar(selected);
    setFoto(null);
    close();
  }

  function onChange(imageList, addUpdateIndex) {
    console.log(imageList, addUpdateIndex);
    setFoto(imageList);
    setAvatar(null);
    close();
  }

  return (
    <>
      <div className="bg-white max-w-md mx-auto p-5 my-5 rounded-2xl shadow-md">
        <h4 className="mb-2 text-center">¡Tu primer acceso!</h4>
        <p className="text-center text-sm mb-3">
          Realice su registro y preinscríbase.
        </p>

        <ImageUploading value={foto} onChange={onChange} dataURLKey="data_url">
          {({ onImageUpload, onImageRemoveAll, dragProps }) => (
            <div className="flex justify-center items-center mb-5">
              <div className="m-1">
                {foto && foto[0] !== undefined ? (
                  <img
                    src={foto[0].data_url}
                    alt="Avatar"
                    className="object-cover w-24 h-24 rounded-full shadow-lg"
                  />
                ) : (
                  <Avatar
                    img={avatar}
                    className="w-24 h-24 z-0 rounded-full shadow-lg"
                  />
                )}
              </div>
              <div
                style={{
                  float: 'right',
                  marginTop: '50px',
                  marginLeft: '-20px',
                }}
              >
                <button
                  type="button"
                  onClick={handleClickFoto}
                  title="Elije tu avatar o sube tu foto"
                >
                  <img src={editBlue} alt="Avatar" className="z-30" />
                </button>
              </div>
              <Modal>
                <div className="w-72 p-5 bg-white rounded-2xl">
                  <div className="flex justify-end mb-2">
                    <button type="button" onClick={handleClickModalClose}>
                      <img src={closeIcon} alt="Cerrar" />
                    </button>
                  </div>

                  <div className="text-lg text-center">
                    Elije tu avatar o sube tu foto
                  </div>

                  <div className="mt-5 grid grid-cols-2 gap-4">
                    <button type="button" onClick={() => handleClickAvatar(1)}>
                      <Avatar img={1} />
                    </button>
                    <button type="button" onClick={() => handleClickAvatar(2)}>
                      <Avatar img={2} />
                    </button>
                    <button type="button" onClick={() => handleClickAvatar(3)}>
                      <Avatar img={3} />
                    </button>
                    <button type="button" onClick={() => handleClickAvatar(4)}>
                      <Avatar img={4} />
                    </button>
                  </div>

                  <div className="mt-5 flex justify-center items-center">
                    <button
                      type="button"
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <img src={picture} alt="Avatar 1" />
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </ImageUploading>
        <div className="grid grid-cols-1 gap-4 text-sm">
          <label className="block" htmlFor="usuario">
            <span>Usuário:</span>
            <span className="text-red-500">*</span>
            <input
              type="text"
              id="usuario"
              name="usuario"
              className="input-borded block w-full mt-2 rounded-2xl placeholder-gray-300 text-xs p-3"
              placeholder="Introduce un nombre"
            />
          </label>
          <label className="block" htmlFor="usuario">
            <span>Patente vehículo:</span>
            <span className="text-red-500">*</span>
            <input
              type="text"
              id="usuario"
              name="usuario"
              className="input-borded block w-full mt-2 rounded-2xl placeholder-gray-300 text-xs p-3"
            />
          </label>
          <label className="block" htmlFor="usuario">
            <span>Km inicial:</span>
            <span className="text-red-500">*</span>
            <input
              type="number"
              id="usuario"
              name="usuario"
              className="input-borded block w-full mt-2 rounded-2xl placeholder-gray-300 text-xs p-3"
            />
          </label>
          <label className="block" htmlFor="usuario">
            <span>Contraseña:</span>
            <span className="text-red-500">*</span>
            <input
              type="password"
              id="usuario"
              name="usuario"
              className="input-borded block w-full mt-2 rounded-2xl placeholder-gray-300 text-xs p-3"
              placeholder="Introduce contraseña"
            />
          </label>
        </div>
        <div className="text-xs leading-3 mt-3">
          Debe contener mayúscula o un número y ser mayor a 10 carácteres.
        </div>
        <div className="text-xs leading-3 mt-5">(*)Campo obligatorio</div>
        <button type="submit" className="btn-primary block w-full mt-5">
          Acceso
        </button>
      </div>
    </>
  );
}

export default CourierCheckin;
