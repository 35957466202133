import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/Layout';

import Routes from './Routes';

function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Layout>
  );
}

export default App;
