import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CourierCheckin from './pages/CourierCheckin';
import EleccionTipoLogisticaInversa from './pages/EleccionTipoLogisticaInversa';
import ProgramacionDelRetiro from './pages/ProgramacionDelRetiro';
import PuntoPickup from './pages/PuntoPickup';
import CancelarRetiroOReprogramar from './pages/CancelarRetiroOReprogramar';
import Hacer from './pages/Hacer';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={CourierCheckin} />
      <Route
        path="/eleccion-tipo-logistica-inversa/:hash"
        component={EleccionTipoLogisticaInversa}
      />
      <Route
        path="/programacion-del-retiro/:hash"
        component={ProgramacionDelRetiro}
      />
      <Route path="/punto-pickup/:hash" component={PuntoPickup} />
      <Route
        path="/cancelar-retiro-o-reprogramar/:hash"
        component={CancelarRetiroOReprogramar}
      />
      <Route path="/hacer-retiro/" render={() => <Hacer action="retiro" />} />
      <Route path="/hacer-entrada/" render={() => <Hacer action="entrada" />} />
    </Switch>
  );
}

export default Routes;
