/* eslint-disable react/prop-types */
import React from 'react';
import checkedCircleGreen from '../assets/img/checked-circle-green.svg';
import plus from '../assets/img/plus.svg';
import minus from '../assets/img/minus.svg';

function Accordion({
  title,
  isExpanded,
  onExpand,
  onCollapse,
  isValid,
  children,
}) {
  return (
    <>
      <div className="flex flex-row justify-between items-center px-5 py-4">
        <div hidden={!isValid} className="mr-2">
          <img src={checkedCircleGreen} alt="Ok" />
        </div>
        <div className="flex-1 px-2">{title}</div>
        <div>
          {isExpanded ? (
            <button type="button" onClick={onCollapse}>
              <img src={minus} alt="Collapse" />
            </button>
          ) : (
            <button type="button" onClick={onExpand}>
              <img src={plus} alt="Expand" />
            </button>
          )}
        </div>
      </div>
      <div hidden={!isExpanded}>{children}</div>
    </>
  );
}

export default Accordion;
